(function () {
  var HOST = "/trix_uploader/image"

  addEventListener("trix-file-accept", function (event) {
    // Allow file types
    // const acceptedTypes = ['image/jpeg', 'image/png', 'image/x-png', 'image/gif']
    // if ( !acceptedTypes.includes(event.file.type) ){
    //   event.preventDefault()
    //   alert("Only png, jpeg and gifs are supported")
    // }

    // Only support attachment files upto size 1MB!
    // const maxFileSize = 1024 * 1024 // 1MB
    // if (event.file.size > maxFileSize) {
    //   event.preventDefault()
    // alert("Attachment files upto size 1MB is supported")
    // }
  })

  addEventListener("trix-attachment-add", function (event) {
    if (event.attachment.file) {
      uploadFileAttachment(event.attachment)
    }
  })

  function uploadFileAttachment(attachment) {
    uploadFile(attachment.file, setProgress, setAttributes)

    function setProgress(progress) {
      attachment.setUploadProgress(progress)
    }

    function setAttributes(attributes) {
      attachment.setAttributes(attributes)
    }
  }

  function uploadFile(file, progressCallback, successCallback) {
    var key = createStorageKey(file)
    var formData = createFormData(key, file)
    var xhr = new XMLHttpRequest()

    xhr.open("POST", HOST, true)

    xhr.upload.addEventListener("progress", function (event) {
      var progress = event.loaded / event.total * 100
      progressCallback(progress)
    })

    xhr.addEventListener("load", function (event) {
      if (xhr.status == 200) {
        var href, url;
        url = href = JSON.parse(this.responseText).url;

        var attributes = {
          url: url,
          href: href
        }
        successCallback(attributes)
      }
    })

    xhr.send(formData)
  }

  function createStorageKey(file) {
    var date = new Date()
    var day = date.toISOString().slice(0, 10)
    var name = date.getTime() + "-" + file.name
    return ["tmp", day, name].join("/")
  }

  function createFormData(key, file) {
    var data = new FormData()
    data.append("key", key)
    data.append("Content-Type", file.type)
    data.append("file", file)
    return data
  }
})();
